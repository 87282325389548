import React from 'react'
import Carousel from './Carousel'
import './Project.css'
import Card from './Card'
import CardGrid from './CardGrid'

function Project() {
  return (
    <div className='project-container' id='project'>
      <h1 className='project-header'>
        Projects
        <hr className='myhrline'/>
      </h1>
      <h2 className='project-subheading'>
        Examples of work that I have done for clients in the past. More coming soon!
      </h2>
      
        {/* <Carousel/> */}

      <CardGrid />
    </div>
  )
}

export default Project