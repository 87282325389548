
import React, {useEffect} from 'react';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import './App.css';
import Home from './components/pages/Home'
import ReactGA from 'react-ga4';
library.add(fas, faTwitter, faFontAwesome)


function App() {

  const TRACKING_ID = "G-XQ2SVEG8Q4"; // OUR_TRACKING_ID
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({hitType: "pageview", page: "/", Title: "New Pageview"});
  }, []);

  return (
    <div className='site-container'>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home/>} />
        </Routes>
      </Router>
    </div>
  )
}

export default App