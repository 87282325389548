import React, {useEffect} from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import './Button.css';
import './Contact.css';
import './ContactForm.css';

const schema = yup.object({
    firstName: yup.string().max(20).required("First name is required"),
    lastName: yup.string().max(20).required("Last name is required"),
    email: yup.string().email("Must be a valid email address").required("Email is required").min(6).max(120),
    message: yup.string().max(500, "Message must be no more than 500 characters")
}).required();

function ContactForm() {
    useEffect(() =>{
      ReactRecaptcha3.init(process.env.REACT_APP_SITE_KEY).then(
        (status) => {
          console.log(status)
        }
      )
    }, []);

    const {register, reset, handleSubmit, formState:{errors} } = useForm({
      resolver: yupResolver(schema)
    });

    const onSubmit = async (data) => {
     ReactRecaptcha3.getToken().then(
        (token) => {
          console.log(token)
          data.token = token
          let formData = data
          axios.post("https://portfolio-site-api-8quw.onrender.com/form-data", formData)
          .then(response => {
            console.log(response)
          })
          .catch(error => {
            console.log(error)
          })
        }
      )
      reset()
    };
  
  return (
        <form className="form-container error-message" onSubmit={handleSubmit(onSubmit)}>
          <div className="firstName">
            <label>First name</label>
            <input
              type="text"
              {...register("firstName")}
              className='firstName'
              tabIndex="1"
            />
            {errors.firstName && <p>{errors.firstName?.message}</p>}
          </div>
          <div className="lastName">
            <label>Last name</label>
            <input
              type="text"
              {...register("lastName")}
              className='lastName'
              tabIndex="2"
            />
            {errors.lastName && <p>{errors.lastName?.message}</p>}
          </div>
          <div className="email">
            <label>Email</label>
            <input
                type="text"
                {...register("email")}
                id="email"
                className="email"
                placeholder="example@corp.com"
                tabIndex="3"
            />
            {errors.email && <p>{errors.email?.message}</p>}
          </div>
          <div className="message">
            <label>Message</label>
            <textarea
                type="text"
                {...register("message")}
                className="message"
                placeholder="Type your message here"
                tabIndex="4"
            />
            {errors.message && <p>{errors.message?.message}</p>}
          </div>
          <button type="submit" className="btn--medium btn--outline">Submit</button>
        </form>
  )
}

export default ContactForm
