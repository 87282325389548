import React, {useState, useEffect}  from 'react';
import {Link} from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import resume from '../documents/Resume.pdf'
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  }
  const closeMobileMenu = () => {
    setClick(false);
  }

  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
        <nav className={scrolling ? "navbar scrollbar" : "navbar"}>
            <div className="navbar-container">
              <Link to="hero" offset={-100} spy ={true} smooth={true} duration={500} className="navbar-logo" >
                Matt Lutz <FontAwesomeIcon icon="fa-solid fa-laptop-code" size="5x" />
              </Link>
              <div className='menu-icon' onClick={handleClick}>
                <FontAwesomeIcon icon={'fas fa-bars'}/>
              </div>
                <ul className={click ? 'nav-menu active': 'nav-menu'}>
                  <li className='menu-icon' onClick={handleClick}>
                    <FontAwesomeIcon icon={'fas fa-xmark'}/>
                  </li>
                  <li className='nav-item'>
                    <Link to='hero' offset={-100} spy={true} smooth={true} duration={500} className='nav-links' onClick={closeMobileMenu}>
                      Home
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <a href="https://drive.google.com/file/d/1zMFKf7sbPhV2MEcFIMk5p7IU_LbQZWnu/view?usp=drive_link" className='nav-links'>
                      Resume
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a href='https://orderapp.mattlutz.io' className='nav-links'>
                      Order App
                    </a>
                  </li>
                  <li className='nav-item'>
                    <Link to='project' offset={20} spy={true} smooth={true} duration={500} className='nav-links' onClick={closeMobileMenu}>
                        Projects
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <a href='https://github.com/Lutzmatt17' className='nav-links'>
                      Github
                    </a>
                  </li>
                  <li className='nav-item'>
                    <Link to='about' offset={-100} spy={true} smooth={true} duration={500} className='nav-links' onClick={closeMobileMenu}>
                        About
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='contact' spy={true} offset={0} smooth={true} duration={500} className='nav-links' onClick={closeMobileMenu}>
                      Contact
                    </Link>
                  </li>
                </ul>
            </div>
        </nav>
    </>
  )
}

export default Navbar;