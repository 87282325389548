import React from 'react';
import Card from './Card';
import DVASite from '../images/DVASite.png';
import NJLC from '../images/NJLCSite.png';
import SmartHome from '../images/SmartHome.png';
import AutumnRitz from '../images/AutumnRitz.png';
import OrderApp from '../images/OrderApp.png';
import './CardGrid.css';

function CardGrid() {
  return (
    <div className='card-grid-container'>
        <Card link={"https://orderapp.mattlutz.io/"} imgText={"Example Order App Project"} imgSrc={OrderApp}/>
        <Card link={"https://datavaultalliance.com/"} imgText={"Data Vault Alliance"} imgSrc={DVASite}/>
        <Card link={"https://njlchickory.com/"} imgText={"New Jerusalem Lutheran Church"} imgSrc={NJLC}/>
        <Card link={"https://unlimitedsmarthome.com/"} imgText={"Unilimited Smart Home Solutions"} imgSrc={SmartHome}/>
        <Card link={"https://autumnritz.com/"}imgText={"Autumn Ritz Realty"} imgSrc={AutumnRitz}/>
        
    </div>
  )
}

export default CardGrid;