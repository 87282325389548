import * as React from 'react';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import './ProgressBar.css'
import './About.css'
import { Box } from '@mui/material';

export default function LinearDeterminate(proficiency) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Stack className='hide-on-mobile' sx={{ width: 400}} spacing={2}>
      <div className="skills hide-on-mobile">JavaScript</div>
      <Box className='hide-on-mobile'>
        <LinearProgress variant="determinate" value={proficiency.number}/>
        <LinearProgress variant="determinate" value={proficiency.number}/>
      </Box>
      <div className="skills hide-on-mobile">ReactJS</div>
      <Box className='hide-on-mobile'> 
        <LinearProgress variant="determinate" value={proficiency.number}/>
        <LinearProgress variant="determinate" value={proficiency.number}/>
      </Box>
      <div className="skills hide-on-mobile">HTML5</div>
      <Box className='hide-on-mobile'>
        <LinearProgress variant="determinate" value={proficiency.number}/>
        <LinearProgress variant="determinate" value={proficiency.number}/>
      </Box>
      <div className="skills hide-on-mobile">CSS</div>
      <Box className='hide-on-mobile'>
        <LinearProgress variant="determinate" value={proficiency.number}/>
        <LinearProgress variant="determinate" value={proficiency.number}/>
      </Box>
      <div className="skills hide-on-mobile">TailwindCSS</div>
      <Box className='hide-on-mobile'>
        <LinearProgress variant="determinate" value={proficiency.number}/>
        <LinearProgress variant="determinate" value={proficiency.number}/>
      </Box>
      <div className="skills hide-on-mobile">Python</div>
      <Box className='hide-on-mobile'>
        <LinearProgress variant="determinate" value={proficiency.number}/>
        <LinearProgress variant="determinate" value={proficiency.number}/>
      </Box>
    </Stack>
  );
}
