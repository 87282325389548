import React from 'react';
import '../../App.css';
import HomepageHeader from '../HomepageHeader';
import About from '../About';
import Contact from '../Contact'
import Project from '../Project';


function Home () {
  return (
    <>
      <HomepageHeader />
      <About />
      <Project />
      <Contact />
    </>
  );
}

export default Home;