import React from 'react'
import './Button.css'
import './Contact.css'
import ContactForm from './ContactForm'


function Contact() {
  return (
    <div className='contact-container' id='contact'>
      <h1 className='contact-header'>
        Contact
        <hr className='myhrline'/>
      </h1>
      <h2 className='contact-subheading'>
        If you would like to work with me in establishing your home on the internet <br /> please fill out the contact form below and I will get back to you ASAP.
      </h2>
      <ContactForm />
    </div>
  )
}

export default Contact