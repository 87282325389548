import React from "react";
import "./Card.css"

function Card(props) {
  return (
  <a style={{textDecoration: "none"}} href={props.link} target="_blank" rel="noopener noreferrer">
    <div className="card-container">
        <img className="logo" src={props.imgSrc} alt=""/>
        <div className="text-container">
          {props.imgText}
      </div>
    </div>
  </a>
  );
}

export default Card;
