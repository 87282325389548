import React from "react";
import { Button } from "./Button";
import "./HomepageHeader.css";
import { Link } from "react-scroll";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function HomepageHeader() {
  return (
    <div className="header-container" id="hero">
      <h1>
        Hi, I'm Matt,
        <span style={{ color: "#66FCF1" }}> Software Developer</span>.
      </h1>

      <p>
        Looking for exciting
        <span style={{ color: "#66FCF1" }}> React/Python Developer </span>
        opportunities!
      </p>
     
     
      <div className="header-btns">
        <Link
          to="contact"
          offset={20}
          spy={true}
          smooth={true}
          duration={500}
          className="btn-mobile"
        >
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
          >
            Contact Me
          </Button>
        </Link>
        <Link
          to="project"
          offset={20}
          spy={true}
          smooth={true}
          duration={500}
          className="btn-mobile"
        >
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
          >
            View Portfolio <FontAwesomeIcon icon="fa-solid fa-right-long" />
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default HomepageHeader;
