import React from "react";
import "./About.css";
import profilePicture from "../images/profilePicSquare.jpg";
import LinearDeterminate from "./ProgressBar";

function About() {
  
  return (
    <div className="about" id="about">
      <h1>
        About
        <hr className="myhrline" />
      </h1>
      <div className="about_container">
        <img
          className="profilePicSquare"
          src={profilePicture}
          alt="Matt Lutz Profile"
        />
        <div className="skills_header hide-on-mobile">
          Skills
          <div className="skills hide-on-mobile">
            <LinearDeterminate number={100} />
          </div>
        </div>
        <div className="story_header"> My Story</div>
        <div className="story_content">
          My name is Matt Lutz, I graduated from Western Carolina University in
          May of 2020 with degrees in Computer Science and Applied Mathematics.
          After running my own digital marketing agency for the last 2 years, I
          decided I wanted to get back to my roots. As a result I am currently
          looking for exciting opportunities in the world of software
          development. Please reach out if you have any questions or
          opportunities. I look forward to hearing from you!
        </div>
      </div>
    </div>
  );
}

export default About;
